import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import HalfCircleSpinner from '../Componentes/loading'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const LinesChart = ({equipo, tag}) => {
    const [loading, setLoading] = useState(true); // Variable de estado para controlar la carga de datos
    const [vacio, setVacio] = useState(true); // Variable de estado para controlar si el response viene en blanco
    const [data, setData] = useState({
        label: [],
        datasets: [
            {
                label: 'PM1',
                data: [],
                tension: 0.3,
                fill: false,
                borderColor: 'rgba(255, 114, 0, 0.8)',
                backgroundColor: 'rgba(255, 114, 0, 0.8)',
                pointRadius: 0,
                pointBorderColor: 'rgba(0, 0, 0)',
                pointBackgroundColor: 'rgba(255, 114, 0, 0.8)',
                borderWidth: 3
            },
            {
                label: 'PM2.5',
                data: [],
                tension: 0.3,
                fill: false,
                borderColor: 'rgba(86, 86, 86, 0.8)',
                backgroundColor: 'rgba(86, 86, 86, 0.8)',
                pointRadius: 0,
                pointBorderColor: 'rgba(0, 0, 0)',
                pointBackgroundColor: 'rgba(86, 86, 86, 0.8)',
                borderWidth: 3
            },
            {
                label: 'PM10',
                data: [],
                tension: 0.3,
                fill: false,
                borderColor: 'rgba(0, 13, 255, 0.8)',
                backgroundColor: 'rgba(0, 13, 255, 0.8)',
                pointRadius: 0,
                pointBorderColor: 'rgba(0, 0, 0)',
                pointBackgroundColor: 'rgba(0, 13, 255, 0.8)',
                borderWidth: 3
            },
        ]
    });
    
    useEffect(() => {
        
        const url = 'https://www.mine-360.com/api/cabina/sensor/infograf';

        const fetchData = async () => {
            try {
                const response1 = await fetch(url, { 
                  method: "POST",
                  body:JSON.stringify({"equipo": equipo, "sensor": "data_pm1"}),
                  headers: {"Content-Type": "application/json"}
                })
                const data1 = await response1.json();

                const response2 = await fetch(url, { 
                    method: "POST",
                    body:JSON.stringify({"equipo": equipo, "sensor": "data_pm10"}),
                    headers: {"Content-Type": "application/json"}
                  })
                  const data2 = await response2.json();

                const response3 = await fetch(url, { 
                    method: "POST",
                    body:JSON.stringify({"equipo": equipo, "sensor": "data_pm25"}),
                    headers: {"Content-Type": "application/json"}
                  })
                  const data3 = await response3.json();

                // Comprobar si los arrays de datos están vacíos
                if (data1.length === 0 || data2.length === 0 || data3.length === 0) {
                    setVacio(true); // Establecer vacio en true si algún array está vacío
                } else {
                    setVacio(false); // Establecer vacio en false si todos los arrays tienen datos
        
                setData(prevData => ({
                    labels: data1.map(item => {
                        // Obtener la hora y los minutos de la hora_medida
                        const [hora, minutos] = item.hora_medida.split(':');
                        
                        // Restar 4 horas a la hora
                        let nuevaHora = parseInt(hora) - 4;
                        if (nuevaHora < 0) {
                          nuevaHora = 24 + nuevaHora; // Si la hora resultante es negativa, restarle 24 para obtener una hora válida
                        }
                        
                        // Formatear la nueva hora en formato HH:MM
                        if (nuevaHora < 10) {
                          nuevaHora = `0${nuevaHora}`;
                        }
                        
                        const nuevoLabel = `${nuevaHora}:${minutos}`;
                        
                        return nuevoLabel;
                      }),
                    datasets: [
                        { ...prevData.datasets[0], data: data1.map(item => item.data_pm1) },
                        { ...prevData.datasets[1], data: data3.map(item => item.data_pm25) },
                        { ...prevData.datasets[2], data: data2.map(item => item.data_pm10) },
                    ],
                }));

            }

            setLoading(false); 
    
              } catch (error) {
                console.error(error);
              }
    
            // Una vez obtenidos los nuevos datos, actualiza el estado setData con los nuevos valores
        };

        const interval = setInterval(() => {
                fetchData();
            }, 60000);

            fetchData(); // Llamar fetchData al inicializar el componente

            return () => clearInterval(interval);
        }, [equipo]);
        
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
            y: {
                min: 0,
                ticks: { color: 'rgb(0, 0, 0)' },
                grid: { color: 'rgb(0, 0, 0, 0.1)' }
            },
            x: {
                ticks: {
                    color: 'rgb(0, 0, 0)',
                    autoSkip: true,
                    maxRotation: 35,
                    minRotation: 35
                },
                grid: { color: 'rgb(0, 0, 0, 0.1)' }
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: "black",
                },
                title: {
                    display: true,
                    text: 'MATERIAL PARTICULADO ' + tag,
                    color: "black",
                    font: {
                        size: 16,
                        weight: "bold",
                    }
                },
            }
        },
    };

    return (
        <>
        {loading && <HalfCircleSpinner/>}
        {!loading && !vacio &&<Line data={data} options={options}/>}
        {!loading && vacio &&<div style={{ fontWeight: "bold", color: "black"}}>El equipo seleccionado se encuentra apagado, sin embargo puede consultar datos historicos.</div>}
        </>
    );
};

export default LinesChart;