import React from 'react';
import './sidebar.css';
import Newuser from './nuevousuario'
import sesion from '../Imagenes/cerrarsesion.png';
import newusuario from '../Imagenes/nuevousuario.png';
import riego from '../Imagenes/riegoicon.png';
import cabina from '../Imagenes/cabinaicon.png';
import emergency from '../Imagenes/emergency.png';
import formulario from '../Imagenes/formulario.png';
import pdfemergency from '../Documentos/ProtocoloEmergenciaRiegoFijo.pdf';
import menu from '../Imagenes/hamburguer.png'
import tablet from '../Imagenes/tablet.png'
import camaras from '../Imagenes/camaras.png'


const Sidebar = () => {


  const bar = () => {

    if (document.getElementById("bar").style.display==="flex") {
      document.getElementById("bar").style.display="none";
    }else {
      document.getElementById("bar").style.display="flex";
    }
  };

  const nuevo = () => {
  
      document.getElementById("newuser").style.display="flex";
  
  };
  
  const   cerrarsesion = () => {
  
      sessionStorage.removeItem('rol');
      window.location.href = './';
      
      }
  
  const abrirPDFemergency = () => {
    window.open(pdfemergency, '_blank');
  }
  
  const windowriego = () => {
    window.location.href = './riego';
  }
  
  const windowcabina = () => {
    window.location.href = './cabina';
  }

  const windownuevoequipo = () => {
    window.location.href = './nuevoequipo';
  }

  const windownuevochip = () => {
    window.location.href = './nuevochip';
  }

  const windowlistadoequipos = () => {
    window.location.href = './listadoequipos';
  }

  const windowlistadochips = () => {
    window.location.href = './listadochips';
  }
  const windowtablet = () => {
    window.location.href = './tablet';
  }

  const windowcamaras = () => {
    window.location.href = './camaras';
  }
  
  const rol = sessionStorage.getItem('rol'); // función que obtiene el rol del usuario

  return (
    <div>
      <div id="menusidebarnew">
      <div><img src={menu} alt="sidebar" height="50px" width="50px" onClick={bar}></img></div>
      </div>

      <div id="bar" className="sidebar">

        <div className="sidebar" >
          <ul>
            {rol === "1" && <div>
            <li className="sidebar-item" onClick={nuevo}> <img src={newusuario} alt="imagen crear usuario" height="30px" width="30px" />Registrar Nuevo Usuario</li>
            <li className="sidebar-item"> <img src={formulario} alt="imagen crear usuario" height="30px" width="30px" />Equipos
            <ul id="submenu">
              <li onClick={windownuevochip}>Nuevo Chip</li>
              <li onClick={windowlistadochips}>Listado de Chips</li>
              <li onClick={windownuevoequipo}>Nuevo Equipo</li>
              <li onClick={windowlistadoequipos}>Listado de Equipos</li>
            </ul>
            </li>
            </div>}
            <li className="sidebar-item" onClick={windowriego}> <img src={riego} alt="imagen crear usuario" height="30px" width="30px" />Red de Riego</li>
            <li className="sidebar-item" onClick={windowcabina}> <img src={cabina} alt="imagen crear usuario" height="30px" width="30px" />Sensor de Cabina</li>
            <li className="sidebar-item" onClick={windowtablet}> <img src={tablet} alt="imagen crear usuario" height="30px" width="30px" />Tablets</li>
            <li className="sidebar-item" onClick={windowcamaras}> <img src={camaras} alt="imagen crear usuario" height="30px" width="30px" />Camaras</li>
            <li className="sidebar-item" onClick={abrirPDFemergency}> <img src={emergency} alt="imagen crear usuario" height="30px" width="30px" />Protocolo emergencia Riego</li>
            <li className="sidebar-lastitem" onClick={cerrarsesion}><img src={sesion} alt="imagen cerrar sesion" height="30px" width="30px"  />Cerrar Sesion</li>
          </ul>
        </div>

        <div id="newuser"><Newuser/></div>
      
      </div>
    </div>
  );
}

export default Sidebar;